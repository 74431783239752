@import url("https://fonts.googleapis.com/css2?family=Forum&display=swap");

$white: white;
$black: #1b1b1b;
$yellow: #edc900f1;

$background: #121212;
$box-background: #222222dc;
$pageBackground: #e8e8e8;
$font-family: 'Times New Roman', Times, serif;

/* Config */
$color-bg: #111;
$color-particle: #fff;
$spacing: 3260px;
$time-1: 80s;
$time-2: 120s;
$time-3: 150s;
$time-4: 500s;

@mixin colorUnderText {
  font-size: 4rem;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

@mixin Blur($data) {
  filter: blur($data);
  -webkit-filter: blur($data);
}

@mixin centerabsolute {
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

@mixin dialogBackground {
  background-color: rgba($color: #000, $alpha: 0.9) !important;
  border-radius: .5rem;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.5); 
  // -webkit-backdrop-filter: blur(4px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  // backdrop-filter: blur(4px); /* Supported in all major browsers */
}

::ng-deep .mat-mdc-dialog-surface {
  overflow-y: initial;
}

.mat-dialog-container {
  height: initial;
  overflow: initial;
}
.mat-dialog-content {
  overflow: auto;
}

@function particles($max) {
   $val: 0px 0px $color-particle;
   @for $i from 1 through $max {
      $val: #{$val},
      random($spacing)+px random($spacing)+px $color-particle;
   }
   @return $val;
}

@mixin particles($max) {
   box-shadow: particles($max);
}



.animation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: 800px;
}

.particle-1 {
  // animation: animParticle $time-1 linear infinite;
  @include particles(300);
  height: 1px;
  width: 1px;
}

.particle-1:after {
  @include particles(300);
  height: 1px;
  width: 1px;
}

.particle-2 {
  // animation: animParticle $time-2 linear infinite;
  @include particles(120);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(120);
  height: 2px;
  width: 2px;
}

.particle-3 {
  // animation: animParticle $time-3 linear infinite;
  @include particles(50);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(50);
  height: 3px;
  width: 3px;
}

.particle-4 {
  // animation: animParticle $time-4 linear infinite;
  @include particles(200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(200);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from { transform: translateY(0px); }
  to   { transform: translateY(1800px * -1); }
}