@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "../src/app/element";

body {
  font-family: $font-family;
  font-size: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #121212;
}

.noscroll {
  overflow: hidden;
}

.closeIcon {
  position: absolute !important;
  right: 3% !important;
  top: 3% !important;
  font-size: 40px !important;
  height: 40px !important;
  width: 40px !important;
  cursor: pointer !important;
  color: white;
  z-index: 2000 !important;
}

.mat-orange {
  background-color: $yellow !important;

  &:disabled {
    background-color: color-mix(in srgb, $yellow 90%, black 50%) !important;
  }
}

.mat-mdc-outlined-button.mat-orange {
  border: 2px solid black !important;
  color: $yellow !important;
  background-color: transparent !important;
  animation: all 1s ease-in;

  &:hover {
    background-color: rgba($yellow, 0.3) !important;
  }
}

@keyframes fadeInBackground {
  100% {
    opacity: 1;
  }
}
